<template>
  <div class="tariffs-detailed container p-0">
    <div class="back-button">
      <BackButton :label="$t('label.subscriptionManagement')" routeToPush="/manage-tariffs" />
    </div>

    <div v-if="!loading">
      <div class="tariffs-detailed__selected-tariff">
        <TariffDetailedCard :tariff="tariff" />
      </div>

      <div class="tariffs-detailed__text">
        <p>{{ $t('label.lookAtMoreTariffs') }}</p>
      </div>

      <div class="tariffs-detailed__tariffs-grid">
        <TariffDetailedCardSmall
          v-for="t in tariffs"
          :key="t.id"
          :tariff="t"
          @select="selectTariff"
        />
      </div>
    </div>
    <div v-else class="f-center flex-grow-1">
      <div>
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BackButton from '@/components/Buttons/BackButton.vue';
import TariffDetailedCard from '@/components/Cards/TariffDetailedCard.vue';
import TariffDetailedCardSmall from '@/components/Cards/TariffDetailedCardSmall.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'TariffsDetailed',
  components: {
    Loader,
    TariffDetailedCard,
    TariffDetailedCardSmall,
    BackButton,
  },
  data() {
    return {
      currentUserTariffsId: +this.$route.params?.tariffId || 0, // 0, 50, 100, 200, 300
      loading: false,
    };
  },
  computed: {
    ...mapGetters({ config: 'config/getAppConfig' }),
    tariff() {
      return this.config.tariffs.find((t) => t.id === this.currentUserTariffsId);
    },
    tariffs() {
      return this.config.tariffs.filter((t) => t.id !== this.currentUserTariffsId);
    },
  },
  methods: {
    selectTariff(id) {
      this.currentUserTariffsId = id;
    },
  },
};
</script>
