<template>
  <div class="tariff-detailed-card lms-card">
    <transition name="component-fade" mode="out-in">
      <div :key="tariff.id" class="tariff-detailed-card__wrapper" :class="tariffImageClass">
        <div class="tariff-detailed-card__left">
          <div class="card-info">
            <p class="tariff-detailed-card__tariff-name">{{ $t(tariff.name) }}</p>
            <ul class="tariff-detailed-card__description">
              <li
                v-for="text in tariff.description"
                :key="text"
                class="tariff-detailed-card__description"
              >
                {{ $t(text) }}
              </li>
            </ul>
            <div v-if="tariff.id !== 0" class="tariff-detailed-card__bonus">
              <ul>
                <li v-for="(b, index) in tariff.bonus" :key="index">
                  {{ $t(b) }}
                </li>
              </ul>
            </div>
            <div v-else class="tariff-detailed-card__constrains">
              <p>{{ $t('label.restrictionCannotAddStudents') }}</p>
            </div>
          </div>
          <div v-if="!isTariffFree" class="tariff-detailed-card__select-tariff">
            <div>
              <div class="tariff-detailed-card__period" :class="{ 'active-right': isSelectedYear }">
                <p @click="disableChoose ? null : !isTariffFree ? (isSelectedYear = false) : null">
                  {{ $t('label.month') }}
                </p>
                <LmsSwitch
                  :value.sync="isSelectedYear"
                  size="large"
                  :disabled="disableChoose || isTariffFree"
                />
                <p @click="disableChoose ? null : !isTariffFree ? (isSelectedYear = true) : null">
                  {{ $t('label.year') }}
                </p>
              </div>
            </div>
            <div class="tariff-detailed-card__action">
              <AddButton
                :title="buttonLabel"
                size="small"
                @click="changeTariff"
                :disabled="!isEmailConfirm || loading"
              />
              <div v-if="!isTariffFree" class="tariff-detailed-card__price">
                <transition name="component-fade" mode="out-in">
                  <p :key="selectedTariff.price">{{ selectedTariff.price.toLocaleString() }} ₽</p>
                </transition>
              </div>
              <p
                class="tariff-detailed-card__error"
                :class="{ 'tariff-detailed-card__error--visible': error }"
              >
                {{ error }}
              </p>
            </div>
            <div v-if="userTariff.id !== 0" class="tariff-detailed-card__hint">
              <AsteriskIcon />{{ $t('supportText.newTariffWillBeNext') }}
            </div>
          </div>
        </div>
        <div class="tariff-detailed-card__right"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LmsSwitch from '@/components/LmsSwitch.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import SubscriptionService from '@/services/subscription.service';
import { tariffPlans } from '@/utils/tariff';
import AsteriskIcon from '@/components/Icons/AsteriskIcon.vue';

export default {
  name: 'TariffDetailedCardSmall',
  components: { AsteriskIcon, LmsSwitch, AddButton },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      paymentResponse: null,
      isSelectedYear: false,
      isSelectedLegal: false,
      error: null,
      isEmailConfirm: true,
      disableChoose: false,
    };
  },
  computed: {
    ...mapGetters({
      doesHaveActiveTariff: 'auth/doesHaveActiveTariff',
      isEmailConfirmed: 'auth/isEmailConfirmed',
      userTariff: 'auth/getUserTariff',
    }),
    tariffImageClass() {
      return `tariff-${this.tariff.id}`;
    },
    isTariffFree() {
      return this.tariff.id === 0;
    },
    selectedTariff() {
      const tariffsToSelect = tariffPlans.filter((t) => t.name === this.tariff.name);
      if (tariffsToSelect.id === 0) {
        return tariffsToSelect[0];
      }
      return this.isSelectedYear ? tariffsToSelect[1] : tariffsToSelect[0];
    },
    buttonLabel() {
      if (this.userTariff.membership?.id !== 0 && this.userTariff.isAutoProlongation) {
        return this.$t('buttonLabels.chooseTariff');
      }
      return this.isTariffFree ? this.$t('buttonLabels.select') : this.$t('buttonLabels.pay');
    },
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    async changeTariff() {
      this.error = null;

      if (this.userTariff.membership?.id !== 0 && this.userTariff.isAutoProlongation) {
        return this.changeProlongation();
      }

      if (this.userTariff.prolongation?.id !== 0) {
        return this.setToaster({
          type: 'toast-warning',
          toast: {
            title: this.$t('errorMessages.alreadyHaveProlongation'),
            body: this.$t('errorMessages.waitUntilCurrentTariffExpired'),
          },
        });
      }

      const data = await this.makePayment();
      if (data?.link) {
        this.showModalWithLink(data.link);
      }
    },
    changeProlongation() {
      return this.$bvModal
        .msgBoxConfirm(this.$t('supportText.areYouSureToChangeTariff'), {
          size: 'sm',
          okVariant: 'primary',
          cancelVariant: 'secondary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer change-tariff__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body change-tariff__body',
          okTitle: this.$t('buttonLabels.change'),
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then(async (bool) => {
          if (bool) {
            await this.makePayment();
            this.setToaster({
              type: 'toast-success',
              toast: {
                title: this.$t('header.tariffSuccessfullyChanged'),
                body: `${this.$t('supportText.nowAutoPayTariff')} ${this.$t(this.tariff.name)}`,
              },
            });
          }
        })
        .catch(console.log);
    },
    makePayment() {
      const payload = {
        membership_id: this.selectedTariff.id,
        return_url: `${window.location.origin}/post-paid`,
      };
      this.loading = true;
      return SubscriptionService.makePayment(payload)
        .catch((error) => {
          const verboseError = error?.response?.data?.error;
          if (verboseError === 'you have to ban some students') {
            this.setToaster({
              type: 'toast-warning',
              toast: {
                title: this.$t('errorMessages.needToRemoveStudents'),
                body: this.$t('errorMessages.studentLimitWillBeOver'),
              },
            });
          } else if (verboseError === 'there is a payment currently pending') {
            this.setToaster({
              type: 'toast-warning',
              toast: {
                title: this.$t('errorMessages.previousPaymentInProcess'),
                body: this.$t('errorMessages.waitUntilPreviousPaymentDone'),
              },
            });
          } else {
            this.setToaster({
              type: 'toast-warning',
              toast: {
                title: this.$t('errorMessages.needToRemoveStudents'),
                body: verboseError || this.$t('errorMessages.studentLimitWillBeOver'),
              },
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    showModalWithLink(link) {
      const paymentLinkButton = this.$createElement(
        'a',
        {
          attrs: {
            href: link,
            target: '_blank',
          },
          class: 'link',
        },
        this.$t('buttonLabels.continuePayment'),
      );

      this.$bvModal
        .msgBoxConfirm(this.$t('supportText.confirmPayment'), {
          size: 'sm',
          okVariant: 'primary',
          cancelVariant: 'secondary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer link-footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: paymentLinkButton,
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then((confirm) => {
          if (confirm) {
            this.setToaster({
              type: 'toast-warning',
              toast: {
                title: this.$t('header.tariffChange'),
                body: this.$t('supportText.tariffWillBeChangeAfterPayment'),
              },
              autoHideDelay: 30000,
            });
          }
        });
    },
    openConfirmationUrl({ data }) {
      this.paymentResponse = data;
      if (data?.confirmation?.confirmation_url) {
        window.open(data.confirmation.confirmation_url, 'true');
      } else {
        this.paymentResponse = { ...data, failed_to_get_confirmation_url: true };
      }
    },
  },
  watch: {
    tariff: {
      handler() {
        this.error = null;
      },
      deep: true,
    },
  },
};
</script>
