<template>
  <div
    class="tariff-detailed-card-small lms-card"
    :class="tariffImageClass"
    @click="$emit('select', tariff.id)"
  >
    <div class="tariff-detailed-card-small__title">
      <p>{{ $t(tariff.name) }}</p>
    </div>
    <ul class="tariff-detailed-card-small__list">
      <li v-for="(item, index) in tariff.description" :key="index">
        {{ $t(item) }}
      </li>
    </ul>
    <div class="tariff-detailed-card-small__bonus" v-if="tariff.id !== 0">
      <PlusIcon />
      <p>{{ $t('label.allBasicFeatures') }}</p>
    </div>
    <div class="tariff-detailed-card-small__constrains" v-else>
      <p>{{ $t('label.restrictionCannotAddStudents') }}</p>
    </div>
    <div class="tariff-detailed-card-small__price">
      <p v-if="tariff.id !== 0">
        <span>{{ tariff.price.toLocaleString() }} </span> {{ $t('label.payInMonth') }}
      </p>
      <p v-else>{{ $t('label.free') }}</p>
    </div>
  </div>
</template>

<script>
import PlusIcon from '@/components/Icons/PlusIcon.vue';

export default {
  name: 'TariffDetailedCardSmall',
  components: { PlusIcon },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tariffImageClass() {
      return `tariff-${this.tariff.id}`;
    },
  },
};
</script>
